import { FeatureSectionItemElementType } from 'library/types/strapi/components'
import Icon from 'library/components/Icon'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  sectionItems: FeatureSectionItemElementType[]
}

const SectionItems = ({ sectionItems }: Props) => {
  const scheme = useBlockScheme()
  const breakpoint = useBreakpoint()

  const isMobile = ['xs', 'sm'].includes(breakpoint)

  return (
    <>
      {sectionItems.map((sectionItem) => (
        <Container $scheme={scheme} key={sectionItem.id}>
          <Title
            tag='p'
            type={isMobile ? 'body-small-400' : 'body-400'}
            color={scheme === 'dark' ? 'white80' : 'charcoal80'}
          >
            {sectionItem.title}
          </Title>

          {!isEmpty(sectionItem.description?.trim()) ? (
            <Description tag='p' type={isMobile ? 'body-small-550' : 'body-550'}>
              {sectionItem.description}
            </Description>
          ) : (
            <Description>
              <Icon name='tick-large' color={scheme === 'dark' ? 'white' : 'charcoal'} />
            </Description>
          )}
        </Container>
      ))}
    </>
  )
}

export default SectionItems

type TextProps = {
  isMobile?: boolean
}

const Container = styled.div<{ $scheme: SchemeType }>`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 0;
  user-select: none;
  border-bottom: 1px solid
    ${({ theme, $scheme }) => ($scheme === 'dark' ? theme.colors.white20 : theme.colors.charcoal10)};

  :last-child {
    border: none;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 17px 0;
  }
`

const Title = styled(Text)<TextProps>`
  width: 159.5px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`

const Description = styled(Text)<TextProps>`
  width: 159.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }
`
