import React from 'react'
import styled from 'styled-components'

const FormBlockBlob = () => (
  <Container>
    <svg xmlns='http://www.w3.org/2000/svg' width='626' height='965' fill='none' viewBox='0 0 626 965'>
      <g filter='url(#filter0_f_9285_7219)'>
        <path
          fill='url(#paint0_linear_9285_7219)'
          d='M286.782 280.415c106.311 106.311 197.817 368.113 92.782 473.149-105.036 105.035-366.838 13.529-473.15-92.782-106.31-106.311-107.344-277.642-2.309-382.677C9.14 173.07 180.471 174.104 286.782 280.415z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_f_9285_7219'
          width='1000'
          height='1000'
          x='-374'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_9285_7219' stdDeviation='100'></feGaussianBlur>
        </filter>
        <linearGradient
          id='paint0_linear_9285_7219'
          x1='-95.894'
          x2='379.565'
          y1='278.105'
          y2='753.564'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A8D2F0' stopOpacity='0.5'></stop>
          <stop offset='1' stopColor='#9FA9FF' stopOpacity='0.5'></stop>
        </linearGradient>
      </defs>
    </svg>
  </Container>
)

export default FormBlockBlob

const Container = styled.div`
  position: absolute;
  z-index: -1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    top: 130px;
    left: -200px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    top: 100px;
    left: -180px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    top: 10px;
    left: -150px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    top: -80px;
  }

  svg {
    display: none;
    overflow: visible;

    @media ${({ theme }) => theme.breakpoints.sm} {
      width: 366px;
      height: 708px;
      display: block;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      width: 467px;
      height: 832px;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      width: 626px;
      height: 965px;
    }
  }
`
