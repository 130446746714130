import React, { ReactNode } from 'react'

import styled from 'styled-components'

type Props = {
  className?: string
  children: ReactNode
}

const Grid = ({ className, children }: Props) => <Container className={className}>{children}</Container>

export default Grid

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
`
