import React, { useRef } from 'react'

import Flex from 'library/components/Flex'
import { SchemeType } from 'theme'
import { StatElementType } from 'library/types/strapi/components'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useCountUpAnimation from '../hook/useCountUpAnimation'

type Props = {
  scheme: SchemeType
  stat: StatElementType
}

const Stat = ({ scheme, stat }: Props) => {
  const statRef = useRef<HTMLDivElement>(null)
  const currentNumber = useCountUpAnimation(statRef, stat.number, 2500)

  return (
    <Container ref={statRef}>
      <Flex>
        <Text type='mega'>{currentNumber}</Text>
        {stat.withPercentage && (
          <Text tag='span' type='mega' color={scheme === 'dark' ? 'white40' : 'charcoal40'}>
            %
          </Text>
        )}
      </Flex>

      <Text tag='h4'>{stat.description}</Text>
    </Container>
  )
}

export default Stat

const Container = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 64px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 4;
    padding-right: 24px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-right: 48px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-right: 64px;
  }
`
