import Block, { Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import CarouselSlider from './components/CarouselSlider'
import Flex from 'library/components/Flex'
import { HeroWithCarouselBlockType } from 'library/types/strapi/components'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const HeroWithCarousel = ({
  scheme,
  prefix,
  heading,
  body,
  buttons = [],
  slides,
  zIndex,
}: HeroWithCarouselBlockType) => {
  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'xs'

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <HeroContainer>
            <Flex column gap={16} center>
              <Prefix tag='h4' color={scheme === 'dark' ? 'aquamarine' : 'blue'}>
                {prefix}
              </Prefix>
              <Heading tag='h1'>{heading}</Heading>
            </Flex>

            <Body type='body-400' tag='p'>
              {body}
            </Body>

            {buttons.length > 0 && (
              <ButtonContainer>
                {buttons.map((item) => (
                  <Button key={item.id} variant={item.type} size={item.size} href={item.link} block={isMobile}>
                    {item.text}
                  </Button>
                ))}
              </ButtonContainer>
            )}
          </HeroContainer>
        </Container>
      </Centraliser>

      <CarouselCentraliser>
        <CarouselContent>
          <Container>
            <CarouselSlider slides={slides} />
          </Container>
        </CarouselContent>
      </CarouselCentraliser>
    </Block>
  )
}

export default HeroWithCarousel

const Prefix = styled(Text)``
const Body = styled(Text)``
const Heading = styled(Text)`
  padding: 0 32px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 64px;
  padding-bottom: 80px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 120px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 80px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-bottom: 160px;
  }
`

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-column: span 2;
  gap: 16px;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 2 / 12;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    margin: 0 24px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 3 / 11;
    margin: 0 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 48px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 16px;
  }
`

const CarouselCentraliser = styled.div`
  width: 100%;
  max-width: 1640px;
  margin: auto;
  display: flex;
  justify-content: center;
`

const CarouselContent = styled.div`
  width: 343px;
  margin: 0 13px;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 680px;
    margin: 0 36px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 960px;
    margin: 0 48px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 1120px;
    margin: 0 54px;
  }
`
