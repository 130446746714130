import Block, { Centraliser } from 'library/components/Block'

import DragSlider from '../Common/DragSlider'
import { ImageCarouselBlockType } from 'library/types/strapi/components'
import ImageCarouselCard from './components/ImageCarouselCard'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'
import useMeasure from 'react-use-measure'

const ImageCarouselBlock = ({ scheme, heading, cards, zIndex }: ImageCarouselBlockType) => {
  const [ref, { width }] = useMeasure()

  const breakpoint = useBreakpoint()

  const type = breakpoint === 'md' ? 'h3' : 'h2'

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Content>
            <Text tag='h2' type={type}>
              {heading}
            </Text>
          </Content>

          <DragSlider cardWidth={width}>
            {cards.map((card) => (
              <ImageCarouselCard key={card.id} ref={ref} card={card} />
            ))}
          </DragSlider>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ImageCarouselBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 48px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  margin-right: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / 9;
    margin-right: 0;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-column: 1 / 8;
  }
`
