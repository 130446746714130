import React, { useMemo, useState } from 'react'
import Switch, { SwitchValue } from 'library/components/Switch'

import Button from 'library/components/Button'
import Flex from 'library/components/Flex'
import Image from 'next/image'
import Input from 'library/components/Input'
import PricingAccent from 'public/library/images/onetrace-website-stock.png'
import Text from 'library/components/Text'
import formatAmount from 'helpers/formatAmount'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const PricingCard = () => {
  const [seats, setSeats] = useState('5')
  const [interval, setInterval] = useState<SwitchValue>('monthly')

  const breakpoint = useBreakpoint()

  const inclusionTextType = ['xs', 'sm'].includes(breakpoint) ? 'body-small-550' : 'body-small-400'

  const seatRange = useMemo(() => {
    return [{ label: '5 Seats (minimum)', value: '5' }]
      .concat(
        Array.from({ length: 44 }, (_, index) => ({
          label: `${index + 6} Seats`,
          value: `${index + 6}`,
        })),
      )
      .concat({ label: '50+ Seats', value: '50+' })
  }, [])

  const price = useMemo(() => {
    const yearlyPrice = 32.4
    const monthyPrice = 36

    return interval === 'yearly' ? yearlyPrice : monthyPrice
  }, [interval])

  const total = useMemo(() => {
    const seatPrice = price * Number(seats)
    const totalPrice = interval === 'yearly' ? seatPrice * 12 : seatPrice

    return totalPrice
  }, [seats, price])

  const inclusions = useMemo(
    () => [
      { left: 'Price per seat', right: `${formatAmount(price)} /month` },
      { left: 'Unlimited projects', right: 'Included' },
      { left: 'Unlimited drawings', right: 'Included' },
      { left: 'Unlimited jobs', right: 'Included' },
    ],
    [price],
  )

  return (
    <Container>
      <Content>
        <Input.Select
          name='seats'
          scheme='dark'
          value={seats}
          options={seatRange}
          onChange={(e) => setSeats(e.target.value)}
        />

        <Divider />

        <Inclusions $show={seats !== '50+'}>
          <Switch scheme='dark' value={interval} onChange={(_, value) => setInterval(value)} />

          <Flex column gap={7}>
            {inclusions.map((item, index) => (
              <Flex key={index} justifyContent='space-between'>
                <Text tag='p' type='body-small-550' color='white'>
                  {item.left}
                </Text>
                <Text tag='p' type={inclusionTextType} color='white60' align='right'>
                  {item.right}
                </Text>
              </Flex>
            ))}
          </Flex>

          <Divider />

          <Total>
            <TotalText tag='p' type='body-small-550' color='white'>
              Total per {interval === 'monthly' ? 'month' : 'year'} <span>exc taxes</span>
            </TotalText>
            <Text tag='p' type='h4' color='white'>
              {formatAmount(total)}
            </Text>
          </Total>
        </Inclusions>

        <ContactSales $show={seats === '50+'}>
          <Text tag='p' type='body-small-400' color='white'>
            Need more than 50 seats? Our Enterprise plan is designed for larger teams, and dedicated support helps scale
            your business. Contact sales for a custom quote.
          </Text>

          <Button href='/contact-sales' scheme='dark' variant='secondary'>
            Contact Sales
          </Button>
        </ContactSales>
      </Content>

      <ImageWrapper>
        <Image src={PricingAccent} alt='Onetrace' width={0} height={0} sizes='100vw' draggable={false} priority />
      </ImageWrapper>
    </Container>
  )
}

export default PricingCard

const Container = styled.div`
  width: 100%;
  min-height: 520px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.md} {
    min-height: 550px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    min-height: 588px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    min-height: 688px;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.colors.teal};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 40px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 48px;
    border-radius: 32px 32px 0px 0px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px dashed ${({ theme }) => theme.colors.white20};
`

const Inclusions = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
`

const Total = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const TotalText = styled(Text)`
  span {
    color: ${({ theme }) => theme.colors.white80};
    ${({ theme }) => theme.typography['body-small-400'].mobile};

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography['body-small-400'].desktop};
    }
  }
`

const ContactSales = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
`

const ImageWrapper = styled.div`
  height: 64px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 24px 24px;
    object-fit: cover;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 0px 0px 32px 32px;
    }
  }
`
