import { SolutionCardsBlockType } from 'library/types/strapi/components'
import { useMemo } from 'react'

const useGetLayoutValue = (__component: SolutionCardsBlockType['__component']) => {
  const layoutValue = useMemo(() => {
    if (__component === 'blocks.solution-cards-x5') {
      return 5
    } else if (__component === 'blocks.solution-cards-x3') {
      return 3
    } else if (__component === 'blocks.solution-cards-x2') {
      return 2
    }

    return 5
  }, [__component])

  return layoutValue
}

export default useGetLayoutValue
