import React, { ReactNode } from 'react'

import CarouselButton from 'library/components/CarouselButton'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'
import useMeasure from 'react-use-measure'
import useSlider from 'hooks/useSlider'

type Props = {
  cardWidth: number
  children: ReactNode
}

const DragSlider = ({ cardWidth, children }: Props) => {
  const breakpoint = useBreakpoint()

  const [ref, { width }] = useMeasure()
  const {
    sliderRef,
    dragHandlers,
    activeKey,
    onNextHandler,
    onPreviousHandler,
    isNextBtnDisabled,
    isPrevBtnDisabled,
    cardsLength,
  } = useSlider()

  const gap = breakpoint === 'xs' ? 16 : 24
  const totalCardsWidth = cardsLength * (cardWidth + gap) - gap
  const maxXTranslation = totalCardsWidth - width

  return (
    <Container ref={ref}>
      <CardsWrapper
        ref={sliderRef}
        animate={{ x: activeKey === 0 ? 0 : -Math.min(maxXTranslation, (cardWidth + gap) * activeKey) }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        onMouseDown={dragHandlers.onMouseDown}
        onMouseMove={dragHandlers.onMouseMove}
        onMouseUp={dragHandlers.onMouseUp}
        onTouchStart={dragHandlers.onTouchStart}
        onTouchMove={dragHandlers.onTouchMove}
        onTouchEnd={dragHandlers.onTouchEnd}
      >
        {children}
      </CardsWrapper>

      <Controls>
        <CarouselButton variant='previous' onClick={onPreviousHandler} disabled={isPrevBtnDisabled} />
        <CarouselButton variant='next' onClick={onNextHandler} disabled={isNextBtnDisabled} />
      </Controls>
    </Container>
  )
}

export default DragSlider

const Container = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 48px;
  }
`

const CardsWrapper = styled(motion.div)`
  display: flex;
  gap: 16px;
  cursor: grab;

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 24px;
  }
`

const Controls = styled.div`
  display: flex;
  gap: 8px;
`
