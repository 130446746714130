import Block, { Centraliser } from 'library/components/Block'
import React, { useMemo, useRef, useState } from 'react'
import Switch, { SwitchValue } from 'library/components/Switch'

import Grid from 'library/components/Grid'
import ParallaxContainer from 'library/components/ParallaxContainer'
import SubscriptionBlockBlob from './Blobs/SubscriptionBlockBlob'
import { SubscriptionBlockType } from 'library/types/strapi/components'
import SubscriptionCard from './components/SubscriptionCard'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const SubscriptionBlock = ({ scheme, prefix, card1, card2, zIndex }: SubscriptionBlockType) => {
  const ref = useRef<HTMLDivElement>(null)

  const [period, setPeriod] = useState<SwitchValue>('monthly')

  const breakpoint = useBreakpoint()

  const animateConfig = useMemo(() => {
    switch (breakpoint) {
      case 'xl':
        return { left: { offset: -255, speed: 0.48 } }
      case 'lg':
        return { left: { offset: -270, speed: 0.5 } }
      case 'md':
        return { left: { offset: -300, speed: 0.55 } }
      case 'sm':
        return { left: { offset: -180, speed: 0.6 } }
      default:
        return { left: { offset: -420, speed: 0.8 } }
    }
  }, [breakpoint])

  return (
    <Block ref={ref} scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <ParallaxContainer targetRef={ref} leftElement={<SubscriptionBlockBlob />} animate={animateConfig}>
          <Container>
            <Header>
              <Switch value={period} onChange={(_, value) => setPeriod(value)} />

              <Text tag='p' type='body-small-550'>
                {prefix}
              </Text>
            </Header>

            <Content>
              <SubscriptionCard
                period={period}
                type='withPrice'
                tag={{ color: 'purple', text: '5-50 Seats' }}
                heading={card1.heading}
                monthly={card1.monthly}
                yearly={card1.yearly}
                button={card1.button}
              />

              <SubscriptionCard
                period={period}
                type='contact'
                tag={{ color: 'orange', text: '50+ Seats' }}
                heading={card2.heading}
                monthly={card2.monthly}
                yearly={card2.yearly}
                button={card2.button}
              />
            </Content>
          </Container>
        </ParallaxContainer>
      </Centraliser>
    </Block>
  )
}

export default SubscriptionBlock

const Container = styled(Grid)`
  row-gap: 24px;
  padding: 48px 0 64px 0;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-bottom: 120px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 64px 0 120px 0;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-bottom: 160px;
  }
`

const Header = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
    gap: 24px;
  }
`
