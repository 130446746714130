import Block, { Centraliser } from 'library/components/Block'
import { ImageDoubleBlockType, ImagePosition } from 'library/types/strapi/components'

import Button from 'library/components/Button'
import Image from 'next/image'
import Link from 'library/components/Link'
import ListItems from '../Common/ListItems'
import React from 'react'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const ImageDoubleBlock = ({
  scheme,
  heading,
  body,
  cta,
  image1,
  image2,
  imagePosition,
  zIndex,
  listItems,
}: ImageDoubleBlockType) => {
  const breakpoint = useBreakpoint()

  const headingType = breakpoint === 'md' ? 'h2' : 'h1'
  const img1Src = getStrapiMedia(image1.data)
  const img2Src = getStrapiMedia(image2.data)

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Images $imagePos={imagePosition}>
            <ImageWrapper>
              <Image
                src={img1Src}
                alt={image1.data.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes='100vw'
              />
            </ImageWrapper>

            <ImageWrapper>
              <Image
                src={img2Src}
                alt={image2.data.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes='100vw'
              />
            </ImageWrapper>
          </Images>

          <Content $imagePos={imagePosition}>
            <Text tag={headingType}>{heading}</Text>

            {body && (
              <Text tag='p' type='body-400'>
                {body}
              </Text>
            )}

            {!!listItems?.length && <ListItems items={listItems} />}

            {cta && (
              <>
                {cta.type !== 'link' ? (
                  <Button variant={cta?.type} size={cta.size} href={cta.link}>
                    {cta.text}
                  </Button>
                ) : (
                  <Link href={cta.link}>{cta.text}</Link>
                )}
              </>
            )}
          </Content>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ImageDoubleBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  grid-template-rows: auto;
  row-gap: 32px;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
`

const Images = styled.div<{ $imagePos: ImagePosition }>`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    align-self: center;
    column-gap: 24px;
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '1 / 7' : '7 / 13')};
    grid-template-columns: repeat(6, 1fr);
  }
`

const ImageWrapper = styled.div`
  align-self: flex-start;
  grid-column: 1 / 2;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / 4;
  }

  :nth-child(2) {
    grid-column: 2 / 2;
    margin-top: 80px;

    @media ${({ theme }) => theme.breakpoints.sm} {
      grid-column: 4 / 7;
    }
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 5;
    border-radius: 24px;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 32px;
    }
  }
`

const Content = styled.div<{ $imagePos: ImagePosition }>`
  align-self: center;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    align-self: center;
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '8 / 13' : '1 / 6')};
    grid-row: 1 / 1;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    align-self: flex-start;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    align-self: center;
    margin-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin-right: 48px;
  }
`
