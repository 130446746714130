import { ColorType, SchemeType } from 'theme'
import React, { forwardRef } from 'react'

import Icon from 'library/components/Icon'
import Image from 'next/image'
import Link from 'library/components/Link'
import Text from 'library/components/Text'
import { TextCardElementType } from 'library/types/strapi/components'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = { card: TextCardElementType }

const CardCarouselCard = forwardRef<HTMLDivElement, Props>(({ card }, ref) => {
  const scheme = useBlockScheme()

  const iconColor: ColorType = scheme === 'light' ? 'charcoal' : 'white'

  return (
    <Container ref={ref} $scheme={scheme}>
      <Content>
        {card.svgIcon?.data && !card.icon && (
          <ImageWrapper>
            <Image
              src={getStrapiMedia(card.svgIcon.data)}
              alt={card.svgIcon.data.attributes.alternativeText || ''}
              width={48}
              height={48}
            />
          </ImageWrapper>
        )}

        {card.icon && !card.svgIcon?.data && <StyledIcon name={card.icon} color={iconColor} />}

        <Text tag='h4'>{card.heading}</Text>

        <Text tag='p' type='body-small-400'>
          {card.body}
        </Text>

        {card.link && <Link href={card.link.url}>{card.link.text}</Link>}
      </Content>
    </Container>
  )
})

CardCarouselCard.displayName = 'CardCarouselCard'

export default CardCarouselCard

const Container = styled.div<{ $scheme: SchemeType }>`
  flex-shrink: 0;
  width: 327px;
  min-height: 219px;
  padding: 24px;
  user-select: none;
  border-radius: 24px;
  background: ${({ theme, $scheme }) => theme.colors[$scheme === 'light' ? 'paleBlue' : 'navyLight']};

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 328px;
    min-height: 220px;
    padding: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 386px;
    min-height: 258px;
    padding: 40px;
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 486px;
    min-height: 324px;
    padding-right: 64px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 569px;
    min-height: 380px;
    padding-right: 120px;
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
`

const StyledIcon = styled(Icon)`
  svg {
    width: 40px;
    height: auto;

    @media ${({ theme }) => theme.breakpoints.md} {
      width: 48px;
    }
  }
`

const ImageWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 48px;
  }

  img {
    width: 100%;
    height: auto;
  }
`
