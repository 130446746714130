import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import { FeatureListBlockType } from 'library/types/strapi/components'
import React from 'react'
import Sections from './components/Sections'
import Text from 'library/components/Text'
import styled from 'styled-components'

const FeatureListBlock = ({ scheme, heading, sections, zIndex }: FeatureListBlockType) => (
  <Block scheme={scheme} zIndex={zIndex}>
    <Centraliser>
      <Grid rowGap={24}>
        <Heading>
          <Text tag='h3'>{heading}</Text>
        </Heading>

        <Content>
          <Sections sections={sections} />
        </Content>
      </Grid>
    </Centraliser>
  </Block>
)

export default FeatureListBlock

const Grid = styled(BaseGrid)`
  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 40px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 40px;
  }
`
