import React, { forwardRef } from 'react'

import { CardElementType } from 'library/types/strapi/components'
import Image from 'next/image'
import Link from 'library/components/Link'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = { card: CardElementType }

const ImageCarouselCard = forwardRef<HTMLDivElement, Props>(({ card }, ref) => {
  const scheme = useBlockScheme()

  return (
    <Container ref={ref}>
      <ImageWrapper $scheme={scheme as SchemeType}>
        <Image
          src={getStrapiMedia(card.image.data)}
          alt={card.image.data.attributes.alternativeText || ''}
          width={0}
          height={0}
          sizes='100vw'
          draggable={false}
        />
      </ImageWrapper>

      <Content>
        <Text tag='h4'>{card.heading}</Text>

        {card.body && (
          <Text tag='p' type='body-small-400' color={scheme === 'dark' ? 'white' : 'charcoal80'}>
            {card.body}
          </Text>
        )}

        {card.link && <Link href={card.link.url}>{card.link.text}</Link>}
      </Content>
    </Container>
  )
})

ImageCarouselCard.displayName = 'ImageCarouselCard'

export default ImageCarouselCard

const Container = styled.div`
  flex-shrink: 0;
  width: 327px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content;
  column-gap: 16px;
  row-gap: 24px;
  user-select: none;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 328px;
    column-gap: 24px;
    grid-template-columns: repeat(6, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 386px;
    grid-template-columns: repeat(5, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 486px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 569px;
  }
`

const ImageWrapper = styled.div<{ $scheme: SchemeType }>`
  grid-column: 1 / -1;
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'navyLight' : 'paleBlue']};
  border-radius: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 220px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 258px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    height: 324px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    height: 380px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-right: 48px;
  }
`
