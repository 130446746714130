import Block, { Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import Flex from 'library/components/Flex'
import { HeroBlockType } from 'library/types/strapi/components'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const HeroBlock = ({ scheme, prefix, heading, body, buttons, zIndex }: HeroBlockType) => {
  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'xs' || breakpoint === 'sm'

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <HeroContainer>
            <Flex column gap={16}>
              {prefix && (
                <Prefix type='h4' color={scheme === 'dark' ? 'aquamarine' : 'blue'}>
                  {prefix}
                </Prefix>
              )}
              <Heading tag='h1' color={scheme === 'dark' ? 'white' : 'charcoal'}>
                {heading}
              </Heading>
            </Flex>

            {body && (
              <Body type='body-400' color={scheme === 'dark' ? 'white' : 'charcoal'}>
                {body}
              </Body>
            )}

            {(buttons || []).length > 0 && (
              <ButtonContainer>
                {buttons.map((item) => (
                  <Button key={item.id} variant={item.type} size={item.size} href={item.link} block={isMobile}>
                    {item.text}
                  </Button>
                ))}
              </ButtonContainer>
            )}
          </HeroContainer>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default HeroBlock

const Prefix = styled(Text)``
const Body = styled(Text)``

const Heading = styled(Text)`
  padding: 0 32px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }
`

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-column: span 2;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 2 / 12;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    margin: 0 24px;
    gap: 24px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 3 / 11;
    margin: 0 32px;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 48px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 16px;
  }
`
