import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import { HeroLeftAlignBlockType } from 'library/types/strapi/components'
import Link from 'library/components/Link'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const HeroLeftAlignBlock = ({ scheme, heading, body, link }: HeroLeftAlignBlockType) => {
  const breakpoint = useBreakpoint()

  return (
    <Block scheme={scheme}>
      <Centraliser>
        <BaseGrid>
          <Content>
            <TextContainer>
              <Text tag={breakpoint === 'md' ? 'h2' : 'h1'}>{heading}</Text>

              <Text tag='p' type='body-400'>
                {body}
              </Text>
            </TextContainer>

            {link && <Link href={link.url}>{link.text}</Link>}
          </Content>
        </BaseGrid>
      </Centraliser>
    </Block>
  )
}

export default HeroLeftAlignBlock

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 2 / 10;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 1 / 10;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 1 / 8;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 16px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 24px;
  }
`
