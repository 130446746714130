import AccordionBlock from './Accordion'
import CalloutBannerBlock from './CalloutBanner'
import CardCarouselBlock from './CardCarousel'
import ContactCardsBlock from './ContactCards'
import FeatureListBlock from './FeatureList'
import FormBlock from './Form'
import GridBlock from './Grid'
import HeroBlock from './Hero'
import HeroLeftAlignBlock from './HeroLeftAlign/HeroLeftAlignBlock'
import HeroWithCarouselBlock from './HeroWithCarousel'
import ImageCarouselBlock from './ImageCarousel'
import ImageDoubleBlock from './ImageDouble'
import ImageSingleBlock from './ImageSingle'
import ImageSingleWithAppBlock from './ImageSingleWithApp'
import LegalBlock from './Legal'
import LogosBlock from './Logos'
import PricingBlock from './Pricing'
import QuoteCarouselBlock from './QuoteCarousel'
import SolutionCardsBlock from './SolutionCards'
import SpacerBlock from './Spacer'
import StatsBlock from './Stats'
import SubscriptionBlock from './Subscription'
import ValuePropositionBlock from './ValueProposition'

const Blocks = {
  accordion: AccordionBlock,
  'callout-banner': CalloutBannerBlock,
  'card-carousel': CardCarouselBlock,
  'contact-cards': ContactCardsBlock,
  'feature-list': FeatureListBlock,
  form: FormBlock,
  grid: GridBlock,
  hero: HeroBlock,
  'hero-left-align': HeroLeftAlignBlock,
  'hero-with-carousel': HeroWithCarouselBlock,
  'image-carousel': ImageCarouselBlock,
  'image-double': ImageDoubleBlock,
  'image-single-with-app': ImageSingleWithAppBlock,
  'image-single': ImageSingleBlock,
  legal: LegalBlock,
  logos: LogosBlock,
  pricing: PricingBlock,
  'quote-carousel': QuoteCarouselBlock,
  'solution-cards-x5': SolutionCardsBlock,
  'solution-cards-x3': SolutionCardsBlock,
  'solution-cards-x2': SolutionCardsBlock,
  spacer: SpacerBlock,
  stats: StatsBlock,
  subscription: SubscriptionBlock,
  'value-proposition': ValuePropositionBlock,
}

export type BlockType = keyof typeof Blocks

export default Blocks
