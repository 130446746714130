import Block from 'library/components/Block'
import Logo from './components/Logo'
import { LogosBlockType } from 'library/types/strapi/components'
import React from 'react'
import Scroller from './components/Scroller'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const LogosBlock = ({ scheme, logos, zIndex }: LogosBlockType) => {
  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'xs'

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Container>
        <Scroller defaultSpeed={35} hoverSpeed={10}>
          {logos.map((logo) => (
            <Logo key={logo.id} id={logo.id} name={logo.name} image={logo.image} />
          ))}
        </Scroller>

        <Scroller defaultSpeed={20} hoverSpeed={10}>
          {logos.map((logo) => <Logo key={logo.id} id={logo.id} name={logo.name} image={logo.image} />).reverse()}
        </Scroller>

        {isMobile && (
          <Scroller defaultSpeed={25} hoverSpeed={10}>
            {logos.map((logo) => (
              <Logo key={logo.id} id={logo.id} name={logo.name} image={logo.image} />
            ))}
          </Scroller>
        )}
      </Container>
    </Block>
  )
}

export default LogosBlock

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 24px;
  }
`
