import { GridElementType } from 'library/types/strapi/components'
import Icon from 'library/components/Icon'
import Image from 'next/image'
import React from 'react'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = {
  item: GridElementType
}

const GridItem = ({ item }: Props) => {
  const scheme = useBlockScheme()

  return (
    <Container>
      <IconWrapper>
        {item.svgIcon.data ? (
          <ImageWrapper>
            <Image src={getStrapiMedia(item.svgIcon.data)} alt='SVG icon' width={24} height={24} />
          </ImageWrapper>
        ) : (
          <Icon name={item.icon} color={scheme === 'dark' ? 'white' : 'charcoal'} />
        )}
      </IconWrapper>

      <Content>
        <Text tag='p' type='body-550'>
          {item.heading}
        </Text>

        <Text tag='p' type='body-small-400' color={scheme === 'dark' ? 'white80' : 'charcoal80'}>
          {item.body}
        </Text>
      </Content>
    </Container>
  )
}

export default GridItem

const Container = styled.div`
  grid-column: 1 / -1;
  display: flex;
  gap: 12px;
  padding-right: 32px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 6;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: span 4;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 4px;
  }
`

const IconWrapper = styled.div`
  padding-top: 3px;
`

const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`
