import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import CarouselSlide from './CarouselSlide'
import Flex from 'library/components/Flex'
import { HeroSlideElementType } from 'library/types/strapi/components'
import HeroWithCarouselLeftBlob from '../Blobs/HeroWithCarouselLeftBlob'
import HeroWithCarouselRightBlob from '../Blobs/HeroWithCarouselRightBlob'
import ParallaxContainer from 'library/components/ParallaxContainer'
import Pips from 'library/components/Pips'
import Slider from 'react-slick'
import Text from 'library/components/Text'
import { motion } from 'framer-motion'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  slides: HeroSlideElementType[]
}

const CarouselSlider = ({ slides }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const sliderRef = useRef<Slider>(null)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [loaded, setLoaded] = useState(false)

  const breakpoint = useBreakpoint()

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slideToScroll: 1,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    adaptiveHeight: slides.length === 1 ? true : false,
    beforeChange: (currentSlide: number, next: number) => {
      setCurrentIndex(next)
    },
  }

  const handleGoToSlide = (id: string) => {
    const index = slideIds.findIndex((item) => item.id === id)

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index)
    }
  }

  const slideIds = useMemo(() => slides.map((slide) => ({ id: String(slide.id) })), [slides])

  const animateConfig = useMemo(() => {
    switch (breakpoint) {
      case 'xl':
        return {
          left: { offset: -320, speed: 0.5 },
          right: { offset: 550, speed: 0.52 },
        }
      case 'lg':
        return {
          left: { offset: -320, speed: 0.5 },
          right: { offset: 480, speed: 0.58 },
        }
      case 'md':
        return {
          left: { offset: -250, speed: 0.45 },
          right: { offset: 420, speed: 0.55 },
        }
      case 'sm':
        return {
          left: { offset: -150, speed: 0.4 },
          right: { offset: 210, speed: 0.55 },
        }
      default:
        return {
          left: { offset: -50, speed: 0.35 },
          right: { offset: 50, speed: 0.45 },
        }
    }
  }, [breakpoint])

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Wrapper>
      <ParallaxContainer
        targetRef={ref}
        animate={animateConfig}
        leftElement={<HeroWithCarouselLeftBlob />}
        rightElement={<HeroWithCarouselRightBlob />}
      >
        <SlidesContainer
          variants={variants}
          initial='initial'
          animate={loaded ? 'animate' : 'initial'}
          transition={{ duration: 0.5, ease: 'easeOut', delay: 0.5 }}
          ref={ref}
        >
          <StyledSlider {...settings} ref={sliderRef} $isSingleSlide={slides.length === 1}>
            {slides.map((slide) => (
              <CarouselSlide slide={slide} key={slide.id} />
            ))}
          </StyledSlider>

          <Flex column gap={24}>
            <CaptionWrapper>
              {slides.map((slide, index) => (
                <Caption key={slide.id} $isActive={index === currentIndex} $zIndex={index}>
                  <Text type='body-small-550' tag='p' align='center'>
                    {slide.caption}
                  </Text>
                </Caption>
              ))}
            </CaptionWrapper>

            {slides.length > 1 && (
              <Pips
                activeId={slideIds[currentIndex].id}
                items={slideIds}
                layout='horizontal'
                onClick={(_, id) => handleGoToSlide(id)}
              />
            )}
          </Flex>
        </SlidesContainer>
      </ParallaxContainer>
    </Wrapper>
  )
}

export default CarouselSlider

const variants = {
  initial: { opacity: 0, y: 30, scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
}

const Wrapper = styled.div`
  grid-column: span 2;
  position: relative;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / -1;
  }
`

const SlidesContainer = styled(motion.div)`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / -1;
    gap: 24px;
  }
`

const StyledSlider = styled(Slider)<{ $isSingleSlide?: boolean }>`
  .slick-list {
    overflow: ${({ $isSingleSlide }) => ($isSingleSlide ? 'hidden' : 'visible')};
    cursor: ${({ $isSingleSlide }) => (!$isSingleSlide ? 'grab' : 'default')};

    ${({ $isSingleSlide }) =>
      $isSingleSlide &&
      css`
        padding: 0 !important;
        transform: none !important;
      `}
  }

  .slick-track {
    width: calc(100vw * 100) !important;

    ${({ $isSingleSlide }) =>
      $isSingleSlide &&
      css`
        width: 343px !important;
        transform: none !important;

        @media ${({ theme }) => theme.breakpoints.sm} {
          width: 680px !important;
        }

        @media ${({ theme }) => theme.breakpoints.md} {
          width: 960px !important;
        }

        @media ${({ theme }) => theme.breakpoints.lg} {
          width: 1120px !important;
        }

        & > div {
          transform: scale(1);
          opacity: 1;
        }
      `}
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    transform: scale(0.9);
    opacity: 0.6;
    transition: ${({ theme }) => theme.transitions.carousel.slider};
  }

  .slick-slide.slick-center {
    transform: scale(1);
    opacity: 1;
  }
`

const Caption = styled.div<{ $isActive?: boolean; $zIndex: number }>`
  width: 279px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ $zIndex }) => $zIndex};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.carousel.opacity};

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 561px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 589px;
  }
`

const CaptionWrapper = styled.div`
  position: relative;
  padding-top: 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 24px;
  }
`
