import Block, { BaseGrid, Centraliser } from 'library/components/Block'
import React, { useMemo, useRef } from 'react'

import Form from './components/Form'
import FormBlockBlob from './Blobs/FormBlockBlob'
import { FormBlockType } from 'library/types/strapi/components'
import FormMarkdownRenderer from './components/FormMarkdownRenderer'
import Link from 'library/components/Link'
import Markdown from 'library/components/Markdown'
import ParallaxContainer from 'library/components/ParallaxContainer'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const FormBlock = ({ scheme, prefix, heading, body, link, zIndex }: FormBlockType) => {
  const ref = useRef<HTMLDivElement>(null)

  const breakpoint = useBreakpoint()

  const animateConfig = useMemo(() => {
    switch (breakpoint) {
      case 'xl':
      case 'lg':
        return {
          left: { offset: -320, speed: 0.7 },
        }
      case 'md':
        return {
          left: { offset: -320, speed: 0.6 },
        }
      default:
        return {
          left: { offset: -320, speed: 0.5 },
        }
    }
  }, [breakpoint])

  return (
    <Block ref={ref} scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <ParallaxContainer targetRef={ref} leftElement={<FormBlockBlob />} animate={animateConfig}>
          <Container>
            <Grid rowGap={48}>
              <Heading>
                <Text tag='p' type='h4' color={scheme === 'dark' ? 'aquamarine' : 'blue'}>
                  {prefix}
                </Text>
                <Text tag='h1' type={breakpoint === 'md' ? 'h2' : 'h1'}>
                  {heading}
                </Text>
                <Body $scheme={scheme}>
                  <Markdown renderer={FormMarkdownRenderer(scheme)} className='body-markdown' tags={['strong', 'p']}>
                    {body}
                  </Markdown>

                  {link && <Link href={link.url}>{link.text}</Link>}
                </Body>
              </Heading>

              <Content>
                <Form />
              </Content>
            </Grid>
          </Container>
        </ParallaxContainer>
      </Centraliser>
    </Block>
  )
}

export default FormBlock

const Container = styled.div`
  padding-bottom: 64px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-bottom: 120px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding-bottom: 160px;
  }
`

const Grid = styled(BaseGrid)``

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / 7;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 1 / 6;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-right: 48px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 7 / 13;
  }
`

const Body = styled.div<{ $scheme: SchemeType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .body-markdown {
    gap: 8px;

    @media ${({ theme }) => theme.breakpoints.sm} {
      gap: 16px;
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 16px;
  }
`
