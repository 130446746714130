import React from 'react'
import { SchemeType } from 'theme'
import styled from 'styled-components'

const FormMarkdownRenderer = (scheme: SchemeType) => {
  const Body = ({ ...props }) => <BodyText $scheme={scheme} {...props} />
  const BodyStrong = ({ ...props }) => <BodyStrongText $scheme={scheme} {...props} />

  const textStyles = {
    p: Body,
    strong: BodyStrong,
  }

  return textStyles
}

export default FormMarkdownRenderer

const BodyText = styled.p<{ $scheme: SchemeType }>`
  color: ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'white' : 'charcoal']};
  ${({ theme }) => theme.typography['body-400'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme }) => theme.typography['body-400'].desktop}
  }
`

const BodyStrongText = styled.strong<{ $scheme: SchemeType }>`
  color: ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'white' : 'charcoal']};
  ${({ theme }) => theme.typography['body-550'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme }) => theme.typography['body-550'].desktop}
  }
`
