import Flex from 'library/components/Flex'
import Icon from 'library/components/Icon'
import Image from 'next/image'
import { ListItemElementType } from 'library/types/strapi/components'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = {
  items: ListItemElementType[]
}

const ListItems = ({ items }: Props) => {
  const scheme = useBlockScheme()

  return (
    <UnorderedList>
      {items.map((item) => (
        <LisItem key={item.id} $hasIcon={!!item.svgIcon?.data || !!item.icon} $scheme={scheme}>
          <Flex>
            {!item.svgIcon?.data && item.icon && (
              <IconContainer>
                <Icon name={item.icon} color={scheme === 'dark' ? 'white' : 'charcoal'} />
              </IconContainer>
            )}

            {!!item.svgIcon?.data && (
              <IconContainer>
                <ImageWrapper>
                  <Image src={getStrapiMedia(item.svgIcon.data)} alt='SVG icon' width={24} height={24} />
                </ImageWrapper>
              </IconContainer>
            )}

            <ListItemText type='body-550' align='left' $hasIcon={!!item.svgIcon?.data || !!item.icon}>
              {item.title}
            </ListItemText>
          </Flex>
        </LisItem>
      ))}
    </UnorderedList>
  )
}

export default ListItems

const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const LisItem = styled.li<{ $scheme: SchemeType; $hasIcon: boolean }>`
  font-size: 1.25rem;
  margin-left: ${({ $hasIcon }) => !$hasIcon && '28px'};
  list-style-type: ${({ $hasIcon }) => ($hasIcon ? 'none' : 'disc')};
  color: ${({ theme, $scheme }) => ($scheme === 'dark' ? theme.colors.white : theme.colors.charcoal)};
`

const IconContainer = styled.div`
  padding: 3px 0;
`

const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`

const ListItemText = styled(Text)<{ $hasIcon?: boolean }>`
  margin-left: ${({ $hasIcon }) => ($hasIcon ? '8px' : '4px')};
`
