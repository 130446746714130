import React, { Children, ReactElement, ReactNode, cloneElement, forwardRef, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

type Props = {
  children: ReactNode
}

const Repeater = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const elementRef = useRef<HTMLDivElement>(null)

  const [overspill, setOverspill] = useState(0)

  useEffect(() => {
    if (!containerRef.current || !elementRef.current) return

    const observer = new ResizeObserver((entries) => {
      if (!containerRef.current) return

      const container = entries[0]
      const containerWidth = container.contentRect.width
      const childWidth = elementRef.current?.offsetWidth || 0

      const repeatsNeeded = containerWidth > 0 ? Math.ceil(containerWidth / childWidth) : 1

      setOverspill(repeatsNeeded * 2 + 1)
    })

    observer.observe(containerRef.current)

    return () => observer.disconnect()
  }, [children])

  const repeatedChildren = [...Array(overspill)].flatMap((_, repeatIndex) =>
    Children.map(children, (child, childIndex) =>
      cloneElement(child as ReactElement, {
        key: `child-${repeatIndex}-${childIndex}`,
      }),
    ),
  )

  return (
    <Container ref={containerRef}>
      <Wrapper ref={ref}>
        <Content ref={elementRef}>{children}</Content>
        <Content>{repeatedChildren}</Content>
      </Wrapper>
    </Container>
  )
})

Repeater.displayName = 'Repeater'

export default Repeater

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`
