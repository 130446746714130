import Block, { Centraliser } from 'library/components/Block'
import { ImagePosition, ImageSingleWithAppBlockType } from 'library/types/strapi/components'

import Button from 'library/components/Button'
import Image from 'next/image'
import Link from 'library/components/Link'
import ListItems from '../Common/ListItems'
import React from 'react'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const ImageSingleWithAppBlock = ({
  scheme,
  heading,
  body,
  cta,
  appImage,
  image,
  imagePosition,
  zIndex,
  listItems,
}: ImageSingleWithAppBlockType) => {
  const breakpoint = useBreakpoint()

  const headingType = breakpoint === 'md' ? 'h2' : 'h1'
  const appImgSrc = getStrapiMedia(appImage.data)
  const imgSrc = getStrapiMedia(image.data)

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Images $imagePos={imagePosition}>
            <ImageWrapper>
              <Image
                src={imgSrc}
                alt={image.data.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes='100vw'
              />
            </ImageWrapper>

            <AppImageWrapper>
              <Image
                src={appImgSrc}
                alt={appImage.data.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes='100vw'
              />
            </AppImageWrapper>
          </Images>

          <Content $imagePos={imagePosition}>
            <Text tag={headingType}>{heading}</Text>

            {body && (
              <Text tag='p' type='body-400'>
                {body}
              </Text>
            )}

            {!!listItems?.length && <ListItems items={listItems} />}

            {cta && (
              <>
                {cta.type !== 'link' ? (
                  <Button variant={cta?.type} size={cta.size} href={cta.link}>
                    {cta.text}
                  </Button>
                ) : (
                  <Link href={cta.link}>{cta.text}</Link>
                )}
              </>
            )}
          </Content>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ImageSingleWithAppBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  grid-template-rows: auto;
  row-gap: 32px;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: repeat(12, 1fr);
    align-items: start;
  }
`

const Images = styled.div<{ $imagePos: ImagePosition }>`
  grid-column: span 2;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '1 / 7' : '7 / 13')};
    margin-left: ${({ $imagePos }) => $imagePos === 'left' && '40px'};
  }
`

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 5;
    border-radius: 24px;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 32px;
    }
  }
`

const AppImageWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-25%);
  margin-bottom: 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    left: -40px;
    transform: translateX(0);
    margin-left: 40px;
  }

  img {
    width: 174px;
    height: auto;
    position: absolute;
    bottom: -40px;
    filter: drop-shadow(0px 55px 85px rgba(55, 53, 48, 0.2));

    @media ${({ theme }) => theme.breakpoints.sm} {
      width: 166px;
      left: -40px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      width: 238px;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      width: 300px;
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
      width: 350px;
    }
  }
`

const Content = styled.div<{ $imagePos: ImagePosition }>`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '8 / 13' : '1 / 6')};
    grid-row: 1 / 1;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    margin-top: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    margin-top: 64px;
    margin-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin-top: 80px;
    margin-right: 48px;
  }
`
