import Block, { Centraliser } from 'library/components/Block'

import Header from './components/Header'
import { QuoteCarouselBlockType } from 'library/types/strapi/components'
import React from 'react'
import Slider from './components/Slider'
import styled from 'styled-components'
import useSlider from 'hooks/useSlider'

const QuoteCarouselBlock = ({ scheme, heading, body, cards, zIndex }: QuoteCarouselBlockType) => {
  const { sliderRef, onNextHandler, onPreviousHandler, dragHandlers, activeKey, isPrevBtnDisabled, isNextBtnDisabled } =
    useSlider(true)

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Header
            heading={heading}
            body={body}
            onNextHandler={onNextHandler}
            onPreviousHandler={onPreviousHandler}
            isPrevBtnDisabled={isPrevBtnDisabled}
            isNextBtnDisabled={isNextBtnDisabled}
            hideControls={cards.length === 1}
          />

          <Slider
            ref={sliderRef}
            cards={cards}
            activeKey={activeKey}
            onPreviousHandler={onPreviousHandler}
            onNextHandler={onNextHandler}
            isPrevBtnDisabled={isPrevBtnDisabled}
            isNextBtnDisabled={isNextBtnDisabled}
            dragHandlers={dragHandlers}
            hideControls={cards.length === 1}
          />
        </Container>
      </Centraliser>
    </Block>
  )
}

export default QuoteCarouselBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }
`
