import Spacer, { SpacerSizeType } from 'library/components/Spacer'

import React from 'react'
import { SpacerBlockType } from 'library/types/strapi/components'
import useBreakpoint from 'hooks/useBreakpoint'

const SpacerBlock = ({ scheme, break744, break1024, break1280, break1560, break1640 }: SpacerBlockType) => {
  const breakpoint = useBreakpoint()

  const removeNumberFromString = (breakpoint: string) => {
    return breakpoint.replace(/\d+/g, '') as SpacerSizeType
  }

  if (breakpoint === 'xs') return <Spacer scheme={scheme} size={removeNumberFromString(break744)} />

  if (breakpoint === 'sm') return <Spacer scheme={scheme} size={removeNumberFromString(break1024)} />

  if (breakpoint === 'md') return <Spacer scheme={scheme} size={removeNumberFromString(break1280)} />

  if (breakpoint === 'lg') return <Spacer scheme={scheme} size={removeNumberFromString(break1560)} />

  if (breakpoint === 'xl') return <Spacer scheme={scheme} size={removeNumberFromString(break1640)} />
}

export default SpacerBlock
