import Block, { Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import React from 'react'
import Stat from './components/Stat'
import { StatsBlockType } from 'library/types/strapi/components'
import Text from 'library/components/Text'
import styled from 'styled-components'

const StatsBlock = ({ scheme, heading, button, stats, zIndex }: StatsBlockType) => (
  <Block scheme={scheme} zIndex={zIndex}>
    <Centraliser>
      <Container>
        <HeaderContainer>
          <Text tag='h2'>{heading}</Text>

          {button && (
            <Button variant={button.type} size={button.size} href={button.link}>
              {button.text}
            </Button>
          )}
        </HeaderContainer>

        <StatContainer>
          {stats.map((stat, index) => (
            <Stat key={`stat-item-${index}`} scheme={scheme} stat={stat} />
          ))}
        </StatContainer>
      </Container>
    </Centraliser>
  </Block>
)

export default StatsBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 48px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 2;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / 9;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-column: 1 / 8;
  }
`

const StatContainer = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
    row-gap: 48px;
  }
`
