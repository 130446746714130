import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import React from 'react'
import SolutionCard from './components/SolutionCard'
import { SolutionCardsBlockType } from 'library/types/strapi/components'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'
import useGetLayoutValue from './hooks/useGetLayoutValue'

const SolutionCardsBlock = ({ __component, scheme, heading, body, cards, zIndex }: SolutionCardsBlockType) => {
  const layout = useGetLayoutValue(__component)
  const breakpoint = useBreakpoint()

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Grid>
          <Heading>
            <Text
              tag='h2'
              type={breakpoint === 'md' ? 'h3' : 'h2'}
              color={scheme === 'dark' ? 'white' : 'charcoal'}
              align='center'
            >
              {heading}
            </Text>

            <Text
              tag='p'
              type={breakpoint === 'xs' ? 'body-small-400' : 'body-400'}
              color={scheme === 'dark' ? 'white60' : 'charcoal'}
              align='center'
            >
              {body}
            </Text>
          </Heading>

          <Content>
            {cards.map((card) => (
              <SolutionCard key={card.id} card={card} layout={layout} />
            ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default SolutionCardsBlock

const Grid = styled(BaseGrid)`
  row-gap: 32px;

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 48px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 2 / 12;
    margin: 0 -24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 3 / 11;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 4 / 10;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 50px;
  }
`

const Content = styled(BaseGrid)`
  grid-column: 1 / -1;
  row-gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    row-gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 2 / 12;
    grid-template-columns: repeat(10, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 3 / 11;
    margin: 0 -24px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin: 0 42px;
  }
`
