import Block, { Centraliser } from 'library/components/Block'
import { ImagePosition, ValuePropositionBlockType } from 'library/types/strapi/components'
import React, { useEffect, useState } from 'react'
import useImageOrientation, { ImageOrientation } from 'hooks/useImageOrientation'

import Image from 'next/image'
import Link from 'library/components/Link'
import Lottie from 'react-lottie'
import { SchemeType } from 'theme'
import Tag from 'library/components/Tag'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const ValuePropositionBlock = ({
  scheme,
  tag,
  heading,
  body,
  link,
  lottieJSON,
  image,
  imagePosition,
  zIndex,
}: ValuePropositionBlockType) => {
  const [loaded, setLoaded] = useState(!!lottieJSON)

  const imgSrc = getStrapiMedia(image.data)

  const breakpoint = useBreakpoint()
  const imageOrientation = useImageOrientation(imgSrc)

  const headingType = breakpoint === 'md' ? 'h3' : 'h2'

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieJSON,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  useEffect(() => {
    if (loaded) return

    if (imageOrientation) setLoaded(true)
  }, [imageOrientation, loaded])

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Content $imagePos={imagePosition}>
            {tag && <Tag color={scheme === 'dark' ? 'white' : tag.color}>{tag.text}</Tag>}

            <Heading type={headingType} tag='h2'>
              {heading}
            </Heading>

            <Body type='body-400' tag='p'>
              {body}
            </Body>

            {link && (
              <Link scheme={scheme} href={link.url}>
                {link.text}
              </Link>
            )}
          </Content>

          {lottieJSON ? (
            <LottieFileContainer $scheme={scheme} $imagePos={imagePosition}>
              <Lottie options={defaultOptions} height='auto' isClickToPauseDisabled />
            </LottieFileContainer>
          ) : (
            <>
              {loaded && (
                <ImageWrapper $scheme={scheme} $imagePos={imagePosition} $imageOrientation={imageOrientation}>
                  <Image
                    src={imgSrc}
                    alt={image.data.attributes.alternativeText || ''}
                    width={0}
                    height={0}
                    sizes='100vw'
                  />
                </ImageWrapper>
              )}
            </>
          )}
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ValuePropositionBlock

const Heading = styled(Text)``
const Body = styled(Text)``

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  grid-template-rows: auto;
  row-gap: 32px;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
  }
`

const LottieFileContainer = styled.div<{ $scheme: SchemeType; $imagePos: ImagePosition }>`
  grid-column: span 2;
  display: flex;
  aspect-ratio: 1 / 1;
  border-radius: 24px;
  background: ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'navyLight' : 'paleBlue']};

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '1 / 7' : '7 / 13')};
    grid-row: 1 / 1;
    aspect-ratio: 1 / 1;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    aspect-ratio: 5 / 4;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '2 / 7' : '7 / 12')};
    margin-left: ${({ $imagePos }) => $imagePos === 'left' && '-19px'};
    margin-right: ${({ $imagePos }) => $imagePos === 'right' && '-19px'};
  }

  div {
    border-radius: 24px;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 32px;
    }
  }
`

const ImageWrapper = styled.div<{ $scheme: SchemeType; $imagePos: ImagePosition; $imageOrientation: ImageOrientation }>`
  grid-column: span 2;
  background: ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'navyLight' : 'paleBlue']};
  border-radius: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '1 / 7' : '7 / 13')};
    grid-row: 1 / 1;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '2 / 7' : '7 / 12')};
    margin-left: ${({ $imagePos }) => $imagePos === 'left' && '-19px'};
    margin-right: ${({ $imagePos }) => $imagePos === 'right' && '-19px'};
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'square' && '1 / 1'};
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'portrait' && '4 / 5'};
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'landscape' && '5 / 4'};
    object-fit: cover;
    border-radius: 24px;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 32px;
    }
  }
`

const Content = styled.div<{ $imagePos: ImagePosition }>`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '7 / 13' : '1 / 7')};
    margin-right: ${({ $imagePos }) => $imagePos === 'right' && '24px'};
    margin-left: ${({ $imagePos }) => $imagePos === 'left' && '24px'};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'right' ? '1 / 6' : '8 / 13')};
    margin-right: 0;
    margin-left: 0;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    margin-right: ${({ $imagePos }) => ($imagePos === 'left' ? '48px' : '32px')};
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'right' ? '2 / 6' : '8 / 12')};
    margin-right: ${({ $imagePos }) => $imagePos === 'left' && '32px'};
    margin-left: -18px;
  }
`
