import CarouselButton from 'library/components/CarouselButton'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading: string
  body?: string
  onNextHandler: () => void
  onPreviousHandler: () => void
  isPrevBtnDisabled: boolean
  isNextBtnDisabled: boolean
  hideControls: boolean
}

const Header = ({
  heading,
  body,
  onPreviousHandler,
  onNextHandler,
  isPrevBtnDisabled,
  isNextBtnDisabled,
  hideControls,
}: Props) => {
  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'sm' || breakpoint === 'xs'

  return (
    <Container>
      <Heading tag='h1'>{heading}</Heading>

      {body && (
        <Body tag='p' type='body-400'>
          {body}
        </Body>
      )}

      {!hideControls && !isMobile && (
        <Controls>
          <CarouselButton variant='previous' onClick={onPreviousHandler} disabled={isPrevBtnDisabled} />
          <CarouselButton variant='next' onClick={onNextHandler} disabled={isNextBtnDisabled} />
        </Controls>
      )}
    </Container>
  )
}

export default Header

const Container = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 16px;
  z-index: 1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: span 4;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 48px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-top: 64px;
    padding-right: 32px;
  }
`

const Heading = styled(Text)`
  grid-column: span 1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / -1;
  }
`

const Body = styled(Text)`
  grid-column: span 2;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 6;
  }
`

const Controls = styled.div`
  display: none;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
  }
`
