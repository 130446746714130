import Button from 'library/components/Button'
import { ContactCardElementType } from 'library/types/strapi/components'
import Flex from 'library/components/Flex'
import Link from 'library/components/Link'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'

type Props = {
  scheme: SchemeType
  card: ContactCardElementType
}

const Card = ({ scheme, card }: Props) => {
  const { heading, body, cta } = card

  return (
    <Container $scheme={scheme}>
      <Flex column gap={8}>
        <CardHeading tag='h4'>{heading}</CardHeading>

        {body && (
          <Body type='body-small-400' tag='p'>
            {body}
          </Body>
        )}
      </Flex>

      {cta && (
        <>
          {cta.type !== 'link' ? (
            <Button variant={cta?.type} size={cta.size} href={cta.link}>
              {cta.text}
            </Button>
          ) : (
            <Link href={cta.link}>{cta.text}</Link>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{ $scheme: SchemeType }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  padding: 24px;
  background-color: ${({ theme, $scheme }) => ($scheme === 'dark' ? theme.colors.navyLight : theme.colors.paleBlue)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 32px;
    gap: 16px;
    min-width: 328px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: 32px;
    padding: 40px;
    gap: 24px;
    min-width: 468px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 40px 64px 40px 40px;
    min-width: 588px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding: 40px 120px 40px 40px;
    min-width: 688px;
  }
`

const CardHeading = styled(Text)``
const Body = styled(Text)``

export default Card
