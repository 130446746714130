import { useCallback, useEffect, useRef, useState } from 'react'

import useBreakpoint from './useBreakpoint'

/**
 * useSlider
 *
 * @param staged this will force the contents of the slider to snap on the left side of the grid - default is false
 */
const useSlider = (staged = false) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const cardsLength = useRef(0)
  const startPosition = useRef(0)
  const isDragging = useRef(false)
  const hasMoved = useRef(false)

  const [active, setActive] = useState(0)

  const breakpoint = useBreakpoint()

  const cutoff = staged ? 1 : breakpoint === 'xs' ? 1 : 2
  const isNextBtnDisabled = active === cardsLength.current - cutoff
  const isPrevBtnDisabled = active === 0
  const threshold = 50 // px before triggering handler

  useEffect(() => {
    if (sliderRef.current) cardsLength.current = sliderRef.current.children.length
  }, [])

  const next = useCallback(() => {
    setActive((prevActive) => (prevActive < cardsLength.current - cutoff ? prevActive + 1 : prevActive))
  }, [setActive, cardsLength, cutoff])

  const previous = useCallback(() => {
    setActive((prevActive) => (prevActive > 0 ? prevActive - 1 : prevActive))
  }, [setActive])

  const startDragging = useCallback((startPositionValue: number) => {
    if (!sliderRef.current) return

    startPosition.current = startPositionValue
    isDragging.current = true
    hasMoved.current = false
    sliderRef.current.style.cursor = 'grabbing'
  }, [])

  const onDrag = useCallback(
    (currentPosition: number) => {
      if (!isDragging.current || hasMoved.current) return

      const diff = currentPosition - startPosition.current

      if (diff > threshold) {
        previous()
        hasMoved.current = true
      } else if (diff < -threshold) {
        next()
        hasMoved.current = true
      }
    },
    [next, previous, threshold],
  )

  const stopDragging = useCallback(() => {
    if (!sliderRef.current) return

    sliderRef.current.style.cursor = 'grab'
    isDragging.current = false
  }, [])

  const dragHandlers: EventHandlers = {
    onMouseDown: (e) => startDragging(e.pageX),
    onMouseMove: (e) => onDrag(e.pageX),
    onMouseUp: stopDragging,
    onMouseLeave: stopDragging,
    onTouchStart: (e) => startDragging(e.touches[0].pageX),
    onTouchMove: (e) => onDrag(e.touches[0].pageX),
    onTouchEnd: stopDragging,
  }

  return {
    sliderRef,
    dragHandlers,
    activeKey: active,
    onNextHandler: next,
    onPreviousHandler: previous,
    isNextBtnDisabled,
    isPrevBtnDisabled,
    cardsLength: cardsLength.current,
  }
}

export default useSlider

export type EventHandlers = {
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseUp: () => void
  onMouseLeave: () => void
  onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
  onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void
  onTouchEnd: () => void
}
