import React, { ReactNode, RefObject } from 'react'
import { motion, useScroll, useSpring, useTransform } from 'framer-motion'

import styled from 'styled-components'

type AnimationConfig = {
  offset: number
  speed: number
}

export type AnimateProps = {
  left?: AnimationConfig
  right?: AnimationConfig
}

type Props = {
  animate: AnimateProps
  leftElement?: ReactNode
  rightElement?: ReactNode
  targetRef: RefObject<HTMLDivElement>
  children: ReactNode
}

const ParallaxContainer = ({ animate, leftElement, rightElement, targetRef, children }: Props) => {
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: targetRef,
    offset: ['center', 'end center'],
  })

  const leftSpeed = animate.left?.speed || 1
  const leftOffset = animate.left?.offset || 0
  const rightSpeed = animate.right?.speed || 1
  const rightOffset = animate.right?.offset || 0

  const leftScroll = useSpring(scrollYProgress, {
    stiffness: Math.min(Math.max(leftSpeed * 1000, 1000), 100),
    damping: 75,
    mass: 1,
  })
  const rightScroll = useSpring(scrollYProgress, {
    stiffness: Math.min(Math.max(rightSpeed * 1000, 1000), 100),
    damping: 75,
    mass: 1,
  })

  const leftY = useTransform(leftScroll, [0, 1], [0, leftOffset])
  const rightY = useTransform(rightScroll, [0, 1], [0, rightOffset])

  return (
    <>
      {leftElement && <Wing style={{ y: leftY }}>{leftElement}</Wing>}

      {children}

      {rightElement && <Wing style={{ y: rightY }}>{rightElement}</Wing>}
    </>
  )
}

export default ParallaxContainer

const Wing = styled(motion.div)`
  position: absolute;
  top: 0;
  z-index: -1;

  :first-child {
    left: 0;
  }

  :last-child {
    right: 0;
  }
`
