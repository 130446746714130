import Block, { Centraliser } from 'library/components/Block'
import { ImagePosition, ImageSingleBlockType } from 'library/types/strapi/components'
import React, { useEffect, useState } from 'react'
import useImageOrientation, { ImageOrientation } from 'hooks/useImageOrientation'

import Button from 'library/components/Button'
import Image from 'next/image'
import Link from 'library/components/Link'
import ListItems from '../Common/ListItems'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const ImageSingleBlock = ({
  scheme,
  heading,
  headingType,
  body,
  cta,
  image,
  imagePosition,
  zIndex,
  listItems,
}: ImageSingleBlockType) => {
  const [loaded, setLoaded] = useState(false)

  const breakpoint = useBreakpoint()

  const headingTextType = breakpoint === 'md' ? 'h3' : headingType
  const imgSrc = getStrapiMedia(image.data)
  const imageOrientation = useImageOrientation(imgSrc)

  useEffect(() => {
    if (imageOrientation) setLoaded(true)
  }, [imageOrientation])

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container $imageOrientation={imageOrientation}>
          {loaded && (
            <ImageWrapper $scheme={scheme} $imagePos={imagePosition} $imageOrientation={imageOrientation}>
              <Image
                src={imgSrc}
                alt={image.data.attributes.alternativeText || ''}
                width={0}
                height={0}
                sizes='100vw'
              />
            </ImageWrapper>
          )}

          <Content $imagePos={imagePosition} $imageOrientation={imageOrientation}>
            <Text tag={headingType} type={headingTextType}>
              {heading}
            </Text>

            {body && (
              <Text tag='p' type='body-400'>
                {body}
              </Text>
            )}

            {!!listItems?.length && <ListItems items={listItems} />}

            {cta && (
              <>
                {cta.type !== 'link' ? (
                  <Button variant={cta?.type} size={cta.size} href={cta.link}>
                    {cta.text}
                  </Button>
                ) : (
                  <Link href={cta.link}>{cta.text}</Link>
                )}
              </>
            )}
          </Content>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ImageSingleBlock

const Container = styled.div<{ $imageOrientation: ImageOrientation }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  grid-template-rows: auto;
  row-gap: 32px;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.sm} {
    column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    align-items: ${({ $imageOrientation }) => $imageOrientation === 'portrait' && 'flex-start'};
  }
`

const ImageWrapper = styled.div<{ $scheme: SchemeType; $imagePos: ImagePosition; $imageOrientation: ImageOrientation }>`
  grid-column: span 2;
  background: ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'navyLight' : 'paleBlue']};
  border-radius: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '1 / 7' : '7 / 13')};
    grid-row: 1 / 1;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: 32px;
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'square' && '1 / 1'};
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'portrait' && '4 / 5'};
    aspect-ratio: ${({ $imageOrientation }) => $imageOrientation === 'landscape' && '5 / 4'};
    object-fit: cover;
    border-radius: 24px;

    @media ${({ theme }) => theme.breakpoints.md} {
      border-radius: 32px;
    }
  }
`

const Content = styled.div<{ $imagePos: ImagePosition; $imageOrientation: ImageOrientation }>`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: ${({ $imagePos }) => ($imagePos === 'left' ? '8/13' : '1/6')};
    grid-row: 1 / 1;
    gap: 24px;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    margin-right: 32px;
    padding-top: ${({ $imageOrientation }) => $imageOrientation === 'portrait' && '64px'};
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    margin-right: 48px;
  }
`
