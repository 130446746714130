import React, { forwardRef } from 'react'

import CarouselButton from 'library/components/CarouselButton'
import { EventHandlers } from 'hooks/useSlider'
import { QuoteCardElementType } from 'library/types/strapi/components'
import QuoteCarouselCard from './QuoteCarouselCard'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'
import useMeasure from 'react-use-measure'

type Props = {
  cards: QuoteCardElementType[]
  activeKey: number
  onNextHandler: () => void
  onPreviousHandler: () => void
  isPrevBtnDisabled: boolean
  isNextBtnDisabled: boolean
  hideControls: boolean
  dragHandlers: EventHandlers
}

const Slider = forwardRef<HTMLDivElement, Props>(
  (
    {
      cards,
      activeKey,
      onNextHandler,
      onPreviousHandler,
      isPrevBtnDisabled,
      isNextBtnDisabled,
      dragHandlers,
      hideControls,
    },
    ref,
  ) => {
    const breakpoint = useBreakpoint()

    const [cardRef, { width }] = useMeasure()

    const gap = breakpoint === 'xs' ? 16 : 24

    return (
      <Container>
        <Content
          ref={ref}
          transition={{
            duration: 0.75,
            ease: 'easeOut',
          }}
          onMouseDown={dragHandlers.onMouseDown}
          onMouseMove={dragHandlers.onMouseMove}
          onMouseUp={dragHandlers.onMouseUp}
          onMouseLeave={dragHandlers.onMouseLeave}
          onTouchStart={dragHandlers.onTouchStart}
          onTouchMove={dragHandlers.onTouchMove}
          onTouchEnd={dragHandlers.onTouchEnd}
        >
          {cards.map((card, index) => {
            const animate = index < activeKey

            return (
              <CardContainer
                key={card.id}
                ref={cardRef}
                animate={{
                  x: animate ? -(width + gap) * index : -(width + gap) * activeKey,
                  scale: animate ? 0.6 : 1,
                  opacity: animate ? 0 : 1,
                  zIndex: index,
                }}
                transition={{
                  duration: 0.75,
                  ease: 'easeInOut',
                  staggerChildren: 1,
                }}
              >
                <QuoteCarouselCard card={card} />
              </CardContainer>
            )
          })}
        </Content>

        {!hideControls && (breakpoint === 'xs' || breakpoint === 'sm') && (
          <Controls>
            <CarouselButton variant='previous' onClick={onPreviousHandler} disabled={isPrevBtnDisabled} />
            <CarouselButton variant='next' onClick={onNextHandler} disabled={isNextBtnDisabled} />
          </Controls>
        )}
      </Container>
    )
  },
)

Slider.displayName = 'Slider'

export default Slider

const Container = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 24px;
  z-index: 0;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 7 / 13;
    grid-template-columns: repeat(7, 1fr);
  }
`

const Content = styled(motion.div)`
  grid-column: 1 / -1;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
`

const CardContainer = styled(motion.div)`
  margin-right: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-right: 24px;
  }
`

const Controls = styled.div`
  display: flex;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`
