import { AnimationPlaybackControls, animate, motion, useMotionValue } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'

import Repeater from './Repeater'
import styled from 'styled-components'
import useMeasure from 'react-use-measure'

type Props = {
  defaultSpeed?: number
  hoverSpeed?: number
  children: ReactNode
}

const Scroller = ({ defaultSpeed = 100, hoverSpeed = 50, children }: Props) => {
  const [duration, setDuration] = useState(defaultSpeed)
  const [endAnimation, setEndAnimation] = useState(false)
  const [rerender, setRerender] = useState(false)

  const [ref, { width }] = useMeasure()

  const xTranslation = useMotionValue(0)

  useEffect(() => {
    let controls: AnimationPlaybackControls
    const finalPosition = -width / 2

    if (endAnimation) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: 'linear',
        duration: (Math.abs(finalPosition) / duration) * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setEndAnimation(false)
          setRerender(!rerender)
        },
      })
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: 'linear',
        duration: Math.abs(finalPosition) / duration,
        repeat: Infinity,
        repeatType: 'loop',
        repeatDelay: 0,
      })
    }

    return () => controls.stop()
  }, [width, xTranslation, duration, rerender, endAnimation, defaultSpeed, hoverSpeed])

  return (
    <Container
      onMouseEnter={() => {
        setEndAnimation(true)
        setDuration(hoverSpeed)
      }}
      onMouseLeave={() => {
        setEndAnimation(true)
        setDuration(defaultSpeed)
      }}
    >
      <motion.div style={{ x: xTranslation }}>
        <Repeater ref={ref}>{children}</Repeater>
      </motion.div>
    </Container>
  )
}

export default Scroller

const Container = styled.div`
  width: 100%;
  position: relative;
  white-space: nowrap;
`
