import { FeatureSectionElementType } from 'library/types/strapi/components'
import React from 'react'
import { SchemeType } from 'theme'
import SectionItems from './SectionItems'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  sections: FeatureSectionElementType[]
}

const Sections = ({ sections }: Props) => {
  const scheme = useBlockScheme()
  const breakpoint = useBreakpoint()

  const isMobile = ['xs', 'sm'].includes(breakpoint)

  return (
    <>
      {sections.map((section) => (
        <div key={section.id}>
          <Heading $scheme={scheme}>
            <Text type={isMobile ? 'body-small-550' : 'body-550'}>{section.heading}</Text>
          </Heading>

          <SectionItems sectionItems={section.sectionItems} />
        </div>
      ))}
    </>
  )
}

export default Sections

const Heading = styled.div<{ $scheme: SchemeType }>`
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, $scheme }) => ($scheme === 'dark' ? theme.colors.white20 : theme.colors.charcoal10)};

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 64px;
  }
`
