import React from 'react'
import styled from 'styled-components'

const HeroWithCarouselLeftBlob = () => (
  <Container>
    <svg xmlns='http://www.w3.org/2000/svg' width='568' height='960' fill='none' viewBox='0 0 568 960'>
      <g filter='url(#filter0_f_8731_2491)'>
        <path
          fill='url(#paint0_linear_8731_2491)'
          d='M228.782 280.415c106.311 106.311 197.817 368.113 92.782 473.149-105.036 105.035-366.838 13.529-473.149-92.782-106.311-106.311-107.345-277.642-2.31-382.677 105.035-105.035 276.366-104.001 382.677 2.31z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_f_8731_2491'
          width='1000'
          height='1000'
          x='-432'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_8731_2491' stdDeviation='100'></feGaussianBlur>
        </filter>
        <linearGradient
          id='paint0_linear_8731_2491'
          x1='-153.894'
          x2='321.565'
          y1='278.105'
          y2='753.564'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A8D2F0' stopOpacity='0.5'></stop>
          <stop offset='1' stopColor='#9FA9FF' stopOpacity='0.5'></stop>
        </linearGradient>
      </defs>
    </svg>
  </Container>
)

export default HeroWithCarouselLeftBlob

const Container = styled.div`
  position: absolute;
  top: -135px;
  left: -50px;
  z-index: -1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    top: -130px;
    left: -250px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    top: -100px;
    left: 0;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    top: 30px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    top: 120px;
  }

  svg {
    width: 244px;
    height: 492px;
    overflow: visible;

    @media ${({ theme }) => theme.breakpoints.sm} {
      width: 464px;
      height: 784px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      width: 540px;
      height: 860px;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      width: 623px;
      height: 960px;
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
      width: 568px;
    }
  }
`
