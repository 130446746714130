import Block, { Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import Grid from 'library/components/Grid'
import { PricingBlockType } from 'library/types/strapi/components'
import PricingCard from './components/PricingCard'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const PricingBlock = ({ scheme, heading, body, button, zIndex }: PricingBlockType) => {
  const breakpoint = useBreakpoint()

  const headingSize = ['lg', 'xl'].includes(breakpoint) ? 'h2' : 'h3'

  return (
    <Block scheme={scheme} zIndex={zIndex}>
      <Centraliser>
        <Container>
          <Content>
            <Text tag='h2' type={headingSize}>
              {heading}
            </Text>

            <Text tag='p' type='body-400'>
              {body}
            </Text>

            <Button href={button.link}>{button.text}</Button>
          </Content>

          <Card>
            <PricingCard />
          </Card>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default PricingBlock

const Container = styled(Grid)`
  grid-row-gap: 48px;
  align-items: center;
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 6;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 1 / 5;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    padding-right: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-right: 48px;
  }
`

const Card = styled.div`
  grid-column: 1 / -1;

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: 6 / 13;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 7 / 13;
  }
`
