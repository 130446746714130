import { useEffect, useState } from 'react'

export type ImageOrientation = 'landscape' | 'portrait' | 'square' | null

const useImageOrientation = (url: string) => {
  const [orientation, setOrientation] = useState<ImageOrientation>(null)

  useEffect(() => {
    if (!url) {
      setOrientation(null)

      return
    }

    const image = new Image()
    image.src = url

    const imgOrientation = () =>
      setTimeout(() => {
        if (image.naturalWidth > image.naturalHeight) {
          setOrientation('landscape')
        } else if (image.naturalWidth < image.naturalHeight) {
          setOrientation('portrait')
        } else if (image.naturalWidth === image.naturalHeight) {
          setOrientation('square')
        }
      }, 0)

    image.onload = imgOrientation

    return () => {
      image.onload = null
      clearTimeout(imgOrientation())
    }
  }, [url])

  return orientation
}

export default useImageOrientation
