import Block, { Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import { CalloutBannerBlockType } from 'library/types/strapi/components'
import Image from 'next/image'
import React from 'react'
import Text from 'library/components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import styled from 'styled-components'

const CalloutBannerBlock = ({ scheme, heading, button, image, zIndex }: CalloutBannerBlockType) => (
  <Block scheme={scheme} zIndex={zIndex}>
    <Centraliser>
      <Container>
        <Content>
          <Text tag='h3' color='white'>
            {heading}
          </Text>

          {button && (
            <Button variant='secondary' size={button.size} href={button.link} scheme='dark'>
              {button.text}
            </Button>
          )}
        </Content>

        <ImageContainer>
          <Image
            src={getStrapiMedia(image?.data)}
            alt={image.data.attributes.alternativeText || ''}
            width={0}
            height={0}
            sizes='100vw'
          />
        </ImageContainer>
      </Container>
    </Centraliser>
  </Block>
)

export default CalloutBannerBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  grid-template-rows: auto;
  min-height: 452px;
  height: auto;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.blue};
  mask: linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0);

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    min-height: 224px;
    column-gap: 24px;
    row-gap: 0;
    align-items: center;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    min-height: 300px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    min-height: 332px;
  }
`

const Content = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 24px 0 24px;
  text-align: left;
  justify-content: left;
  z-index: 1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 1 / 8;
    grid-row: 1 / 1;
    margin: 48px 0 48px 48px;
    max-width: 280px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    margin: 72px 0 72px 72px;
    max-width: 400px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 1 / 7;
    max-width: 480px;
    margin: 88px 0 88px 88px;
  }
`

const ImageContainer = styled.div`
  grid-column: span 2;
  height: 100%;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: 5 / 13;
    grid-row: 1 / 1;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    grid-column: 6 / 13;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-image: linear-gradient(to bottom, transparent 0%, white 18%);
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, white 18%);

    @media ${({ theme }) => theme.breakpoints.sm} {
      mask-image: linear-gradient(to right, transparent 0%, white 20%);
      -webkit-mask-image: linear-gradient(to right, transparent 0%, white 20%);
    }
  }
`
