const formatAmount = (x: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
  })

  return formatter.format(x)
}

export default formatAmount
