import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import { GridBlockType } from 'library/types/strapi/components'
import GridItem from './components/GridItem'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'

const GridBlock = ({ zIndex, scheme, heading, body, list }: GridBlockType) => (
  <Block scheme={scheme} zIndex={zIndex}>
    <Centraliser>
      <Container>
        <Grid>
          <Heading>
            {heading && <Text tag='h2'>{heading}</Text>}

            {body && (
              <Text tag='p' type='body-400'>
                {body}
              </Text>
            )}
          </Heading>

          <List>
            {list.map((item) => (
              <GridItem key={item.id} item={item} />
            ))}
          </List>
        </Grid>
      </Container>
    </Centraliser>
  </Block>
)

export default GridBlock

const Container = styled.div`
  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-top: 24px;
  }
`

const Grid = styled(BaseGrid)`
  row-gap: 48px;

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 64px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 8;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    grid-column: span 7;
  }
`

const List = styled(BaseGrid)`
  grid-column: 1 / -1;
  row-gap: 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    row-gap: 48px;
  }
`
