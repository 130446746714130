import Tag, { TagColors } from 'library/components/Tag'

import Button from 'library/components/Button'
import { ButtonElementType } from 'library/types/strapi/components'
import React from 'react'
import { SwitchValue } from 'library/components/Switch'
import Text from 'library/components/Text'
import styled from 'styled-components'

type TagProps = {
  color: TagColors
  text: string
}

type CardType = 'contact' | 'withPrice'

type Props = {
  period: SwitchValue
  type: CardType
  tag: TagProps
  heading: string
  monthly: string
  yearly?: string
  button: ButtonElementType
}

const SubscriptionCard = ({ period, type, tag, heading, monthly, yearly, button }: Props) => (
  <Wrapper>
    <Container>
      <Tag color={tag.color}>{tag.text}</Tag>

      <Content>
        <Text tag='p' type='h3' color='charcoal'>
          {heading}
        </Text>

        <Text tag='p' type='body-small-400' color='charcoal80'>
          {period === 'yearly' ? yearly || monthly : monthly}
        </Text>
      </Content>

      <WithPriceContent $show={type === 'withPrice'}>
        <SaveText tag='p' type='body-small-550' color='teal' $show={period === 'yearly'}>
          Save 10%
        </SaveText>

        <BilledMonthlyPrice tag='p' type='h2' color='charcoal' $show={period === 'monthly'}>
          £36.00<span>/month</span>
        </BilledMonthlyPrice>

        <BilledYearlyPrice tag='p' type='h2' color='charcoal' $show={period === 'yearly'}>
          £32.40<span>/month</span>
        </BilledYearlyPrice>

        <Text tag='p' type='body-small-400' color='charcoal60'>
          Price per seat. Minimum of 5 seats
        </Text>
      </WithPriceContent>

      <ContactSalesContent $show={type === 'contact'}>
        <Text tag='p' type='body-small-550' color='charcoal'>
          Contact Sales for Price
        </Text>
      </ContactSalesContent>

      <Button href={button.link} block scheme='light'>
        {button.text}
      </Button>
    </Container>
  </Wrapper>
)

export default SubscriptionCard

const Wrapper = styled.div`
  width: 343px;
  min-height: 380px;
  display: flex;
  padding: 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 4px solid #d8e6f7;
  border-radius: 24px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 32px;
    width: 328px;
    height: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    min-height: 468px;
    width: 468px;
    padding: 48px;
    border-width: 6px;
    border-radius: 32px;
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 24px;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const WithPriceContent = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 4px;

  span {
    ${({ theme }) => theme.typography['body-550'].mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography['body-550'].desktop}
    }
  }
`

const SaveText = styled(Text)<{ $show: boolean }>`
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`

const BilledMonthlyPrice = styled(Text)<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`

const BilledYearlyPrice = styled(Text)<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`

const ContactSalesContent = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`
