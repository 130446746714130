import React from 'react'
import styled from 'styled-components'

const SubscriptionBlockLightLeftBlob = () => (
  <Container>
    <svg xmlns='http://www.w3.org/2000/svg' width='650' height='810' fill='none' viewBox='0 0 650 810'>
      <g filter='url(#filter0_f_9252_2510)'>
        <path
          fill='url(#paint0_linear_9252_2510)'
          d='M345.586 260.311c79.734 79.734 148.363 276.085 69.587 354.862-78.777 78.776-275.128 10.147-354.862-69.587-79.733-79.733-80.509-208.231-1.732-287.007 78.776-78.777 207.274-78.001 287.007 1.732z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_f_9252_2510'
          width='850'
          height='850'
          x='-200'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_9252_2510' stdDeviation='100'></feGaussianBlur>
        </filter>
        <linearGradient
          id='paint0_linear_9252_2510'
          x1='58.58'
          x2='415.174'
          y1='258.579'
          y2='615.173'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A8D2F0' stopOpacity='0.5'></stop>
          <stop offset='1' stopColor='#9FA9FF' stopOpacity='0.5'></stop>
        </linearGradient>
      </defs>
    </svg>
  </Container>
)

export default SubscriptionBlockLightLeftBlob

const Container = styled.div`
  position: absolute;
  top: 300px;
  z-index: -1;

  @media ${({ theme }) => theme.breakpoints.sm} {
    top: 15px;
    left: -150px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    top: 200px;
    left: -220px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    top: 150px;
    left: -50px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    top: 150px;
    left: -40px;
  }

  svg {
    width: 500px;
    height: 709px;
    overflow: visible;

    @media ${({ theme }) => theme.breakpoints.sm} {
      width: 464px;
      height: 784px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      width: 578px;
      height: 698px;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      width: 614px;
      height: 774px;
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
      width: 650px;
      height: 810px;
    }
  }
`
